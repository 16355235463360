<template>
  <v-container v-if="vueVeiculo">
    <PageToolbar
      :title="veiculo.placa + ' - ' + veiculo.descricao"
      icon="directions_car"
      dark
      voltar
    />

    <v-card :loading="loading" :disabled="loading" class="mt-6">
      <v-tabs v-model="tab" class="pa-3">
        <v-tab>Dados</v-tab>
        <v-tab>Logs</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-title class="subtitle-1">{{
            $tc("global.dados-gerais")
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <!-- Descricao -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.descricao"
                  :label="$tc('global.descricao')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Placa -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.placa"
                  :label="$tc('veiculo.placa')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Cor -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.cor"
                  :label="$tc('veiculo.cor')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Target Id -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.number="veiculo.target_id"
                  label="Target Id"
                  readonly
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Odometro Base -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.number="veiculo.odometro_base"
                  label="Odometro Base"
                  readonly
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Odometro Atual -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.number="veiculo.odometro_atual"
                  label="Odometro Atual"
                  readonly
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Odometro Limite -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.number="veiculo.odometro_limite"
                  type="number"
                  label="Odometro Limite"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Odometro Alerta -->
              <v-col cols="12" md="4">
                <v-card
                  flat
                  style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                  height="56px"
                  class="pa-3 d-flex justify-space-between align-center"
                >
                  <v-subheader
                    style="height: auto !important"
                    class="ma-0 pa-0 body-1"
                  >
                    Odometro Alerta
                  </v-subheader>

                  <v-switch
                    class="ma-0 pa-0"
                    hide-details
                    :false-value="false"
                    :true-value="true"
                    v-model="veiculo.odometro_alerta"
                  ></v-switch>
                </v-card>
              </v-col>

              <!-- Responsaveis -->
              <v-col cols="12">
                <v-card outlined>
                  <v-card-title class="subtitle-1 lightgray py-3">
                    Responsaveis
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="openAddRespDialog"
                      depressed
                      dark
                      dense
                      color="toolbar"
                      :loading="loadingRespDialog"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-divider
                    v-if="respVeiculo && respVeiculo.length"
                  ></v-divider>
                  <v-card-text
                    v-if="respVeiculo && respVeiculo.length"
                    class="py-4"
                  >
                    <v-chip
                      v-for="(resp, index) in respVeiculo"
                      :key="index"
                      class="ma-1"
                      label
                      close
                      close-icon="delete"
                      @click:close="delUserVeiculo(resp.id)"
                    >
                      <v-icon left>label</v-icon>
                      {{ resp.responsavel }}
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-dialog v-model="respDialog" width="100%" max-width="400">
                <v-card :loading="loadingResp" :disabled="loadingResp">
                  <v-card-title class="subtitle-1 lightgray">
                    Adicionar Responsavel
                    <v-spacer></v-spacer>
                    <v-btn icon @click="respDialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="py-8">
                    <v-select
                      v-model="resp.user_id"
                      :items="usersNotIn"
                      clearable
                      item-text="name"
                      item-value="id"
                      filled
                      dense
                      hide-details
                      label="Responsavel"
                    ></v-select>

                    <v-card
                      flat
                      style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                      height="52px"
                      class="pa-3 mt-4 d-flex justify-space-between align-center"
                    >
                      <v-subheader
                        style="height: auto !important"
                        class="ma-0 pa-0 body-1"
                      >
                        Notifica Whats
                      </v-subheader>

                      <v-switch
                        class="ma-0 pa-0"
                        hide-details
                        :false-value="false"
                        :true-value="true"
                        v-model="resp.notifica_whats"
                      ></v-switch>
                    </v-card>

                    <v-card
                      flat
                      style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                      height="52px"
                      class="pa-3 mt-4 d-flex justify-space-between align-center"
                    >
                      <v-subheader
                        style="height: auto !important"
                        class="ma-0 pa-0 body-1"
                      >
                        Notifica Email
                      </v-subheader>

                      <v-switch
                        class="ma-0 pa-0"
                        hide-details
                        :false-value="false"
                        :true-value="true"
                        v-model="resp.notifica_email"
                      ></v-switch>
                    </v-card>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="toolbar"
                      depressed
                      dark
                      small
                      @click="addResp"
                    >
                      {{ $tc("global.add") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-virtual-scroll
              :items="logsVeiculo"
              :item-height="30"
              height="350"
            >
              <template v-slot="{ item }">
                <v-list-item :key="item.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.created_at | moment("DD/MM/YYYY HH:mm:ss") }} -
                      {{ `Placa: ${veiculo.placa} ` }} - Odometro:
                      <strong>{{ item.odometro }}</strong> km's,
                      {{
                        item.notificado
                          ? "notificação enviada."
                          : "notificação não enviada."
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-btn
      v-if="vueEditVeiculo"
      @click="updateVeiculo"
      :disabled="!valid"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>save</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import {
  getVeiculo,
  putVeiculo,
  getResponsaveis,
  getUsersNotIn,
  deleteResp,
  postRespVeiculo,
  getLogs,
} from "@/services/api/veiculos.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Veiculo",
  props: ["veiculoId"],
  data() {
    return {
      veiculo: {},
      loading: false,
      respVeiculo: [],
      usersNotIn: [],
      logsVeiculo: [],
      respDialog: false,
      loadingRespDialog: false,
      loadingResp: false,
      resp: {},
      tab: 0,
    };
  },
  computed: {
    valid() {
      let valid = false;
      if (this.veiculo.descricao && this.veiculo.placa && this.veiculo.cor) {
        valid = true;
      }
      return valid;
    },
    veiculo_id() {
      return this.$route.params.veiculoId;
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueVeiculo() {
      return this.getAccess("vueVeiculo", "visualizar");
    },
    vueEditVeiculo() {
      return this.getAccess("vueVeiculo", "editar");
    },
  },
  methods: {
    getVeiculo() {
      this.loading = true;

      getVeiculo(this.veiculo_id)
        .then((response) => {
          this.veiculo = response;
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.veiculo.placa
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.veiculo.placa);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUsersNotInVeiculo() {
      return getUsersNotIn(this.veiculo_id)
        .then((response) => {
          this.usersNotIn = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVeiculoResp() {
      getResponsaveis(this.veiculo_id)
        .then((response) => {
          this.respVeiculo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVeiculoLogs() {
      getLogs(this.veiculo_id)
        .then((response) => {
          this.logsVeiculo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateVeiculo() {
      this.loading = true;
      this.$Progress.start();
      putVeiculo(this.veiculo_id, this.veiculo)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.atualizadosucesso"));
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async openAddRespDialog() {
      this.loadingRespDialog = true;
      await this.getUsersNotInVeiculo();
      this.loadingRespDialog = false;
      this.respDialog = true;
    },
    addResp() {
      this.resp.veiculo_id = this.veiculo_id;
      this.$Progress.start();
      this.loadingResp = true;
      postRespVeiculo(this.resp)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Responsavel adicionado com sucesso!");
            this.resp = {};
            this.$Progress.finish();
            this.loadingResp = false;
            this.getVeiculoResp();
            this.respDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loadingResp = false;
        });
    },
    delUserVeiculo(id) {
      this.$Progress.start();
      deleteResp(id)
        .then(() => {
          this.$toast.success(this.$tc("msg.delsucesso"));
          this.getVeiculoResp();
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
  },
  created() {
    this.getVeiculo();
    this.getVeiculoResp();
    this.getVeiculoLogs();
  },
};
</script>

<style lang="scss" scoped></style>
